<template>
  <div class="cateGoryWrap">
    <sovTitle
      @productSearch="productSearch"
      @changeDuration="changeDuration"
      @showPriceFilter="isShowPriceFilter = !isShowPriceFilter"
      :durationProps="this.duration"
      searchText="产品"
    ></sovTitle>
    <div v-if="isShowPriceFilter" class="priceFilter">
      <el-cascader
        placeholder="选择品类"
        v-model="cateValue"
        :options="cateOptions"
        clearable
      >
      </el-cascader>
      <div
        style="
          margin-left: 30px;
          font-size: 16px;
          margin-right: 30px;
          display: flex;
          align-items: center;
        "
      >
        选择价格区间：
        <span
          class="spanInput"
          style="display: inline-block; width: 130px;"
        >
          <el-input
            :maxLength="9"
            onkeyup="value = value.replace(/[^\d]/g, '')"
            @keyup.enter="submitPriceRange"
            v-model="priceFloor"
          ></el-input>
        </span>
        -
        <span
          class="spanInput"
          style="display: inline-block; width: 130px;"
        >
          <el-input
            :maxLength="9"
            onkeyup="value = value.replace(/[^\d]/g, '')"
            @keyup.enter="submitPriceRange"
            v-model="priceCeiling"
          ></el-input>
        </span>
      </div>
      <el-radio-group
          style="margin-left: 40px;margin-right:20px;"
          text-color="#fff"
          v-model="siteType"
          size="mini"
      >
        <el-radio-button label="">全部</el-radio-button>
        <el-radio-button
            v-for="item in sites"
            :key="item.value"
            :label="item.value"
        >{{ item.label }}
        </el-radio-button>
      </el-radio-group>
      <el-button @click="submitPriceRange" size="mini" type="primary"
        >确定</el-button
      >
      <el-button @click="cancelPriceRange" size="mini" plain>重置</el-button>
    </div>
    <div class="categoryTable">
      <div class="tab">
        <span
          style="margin-right: 10px"
          @click="productCompare"
          class="spanClass"
        >
          对比
        </span>
        <span @click="downReport" class="spanClass"> 下载 </span>
        <span class="timeSpan">统计时间：{{ productMonth }}</span>
        <div class="abClass">
          <span>选择</span>
        </div>
      </div>
      <div class="tableMain">
        <el-table
          :height="tableHeight"
          ref="multipleTable"
          v-loading="productListTableLoading"
          :data="cateRankListTable"
          @select="selectFuncHandle"
          :header-cell-style="getRowClass"
          @sort-change="sortChange"
          :default-sort="{ prop: 'exposure', order: 'descending' }"
        >
          <el-table-column
            type="selection"
            :selectable="disabledSelect"
            width="80"
          >
          </el-table-column>
          <el-table-column prop="index" align="center" label="排名" width="65">
          </el-table-column>
          <el-table-column prop="productName" label="产品" align="center">
            <template #default="{ row }">
              <!--              <span-->
              <!--                @click="toProduct(row)"-->
              <!--                style="cursor: pointer; color: rgb(81, 166, 240)"-->
              <!--                >{{ row.productName }}</span-->
              <!--              >-->
              <router-link @click="toProduct(row)" style="cursor: pointer; color: rgb(81, 166, 240)" :to="{path:'sovProduct',query:{product:encodeURI(row.productName),productId:btoa(row.productId),duration:JSON.stringify(duration)}}">{{ row.productName }}</router-link>
            </template>
          </el-table-column>
          <!-- <el-table-column prop="brand" label="所属品牌" align="center">
          </el-table-column> -->
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="exposure"
            label="曝光量"
            align="center"
          >
            <template #header>
              <span style="cursor: pointer">曝光量</span>
            </template>
            <template #default="{ row }">
              {{ sliceDecimal(row.exposure) }}
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="engagementQs"
            label="曝光量"
            align="center"
          >
            <template #header>
              <span style="cursor: pointer">互动量</span>
            </template>
            <template #default="{ row }">
              {{ sliceDecimal(row.engagementQs) }}
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="pgcNum"
            label="PGC发帖数量"
            align="center"
          >
            <template #default="{ row }">
              <span>{{ sliceDecimal(row.pgcNum) }}</span>
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="cost"
            label="投放费用"
            align="center"
          >
            <template #default="{ row }">
              ¥ {{ sliceDecimal(row.cost) }}
            </template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="cpm"
            label="CPM"
            align="center"
            width="100"
          >
            <template #header>
              <span style="cursor: pointer">CPM</span>
            </template>
            <template #default="{ row }"> ¥ {{ row.cpm }}</template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="cpe"
            label="CPE"
            align="center"
            width="100"
          >
            <template #header>
              <span style="cursor: pointer">CPE</span>
            </template>
            <template #default="{ row }"> ¥ {{ row.cpe }}</template>
          </el-table-column>
          <el-table-column
            sortable="custom"
            :sort-orders="['ascending', 'descending']"
            prop="productPrice"
            label="产品单价"
            align="center"
          >
            <template #default="{ row }">
              <span>{{
                row.productPrice === "--"
                  ? "--"
                  : "¥ " + sliceDecimal(row.productPrice)
              }}</span>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          background
          :current-page="currentPage"
          @current-change="nextPage"
          layout="prev, pager, next"
          :page-size="size"
          :total="total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import sovTitle from "./sovTitle";
import {
  getCateTree,
  getCateRankList,
  getProductMonth,
  downLoadSovProductRankingListReport,
  getSovAggMonth,
} from "@/api/sov/sovProduct";
import { mapState, mapMutations } from "vuex";
import storage from "@/utils/storage";

export default {
  data() {
    return {
      cateRankListTable: [],
      page: 1,
      size: 20,
      total: 0,
      duration: [],
      product: "",
      productListTableLoading: false,
      getRowClass: {
        color: "#8C98A5",
      },
      categoryList: [],
      tabType: "A0",
      expandAll: false,
      isExpand: false,
      productList: [],
      productNameList: [],
      productListId: [],
      dialogVisible: false,
      currentPage: 1,
      sortColumn: "exposure",
      sortDirection: "desc",
      isExposurSort: 2,
      isSgSort: 0,
      isSrSort: 0,
      isCpiSort: 0,
      cateOptions: [],
      cateValue: "",
      productMonth: "",
      // 临时统计时间
      tempTime: "",
      cateVal: "",
      priceFloor: "",
      priceCeiling: "",
      isShowPriceFilter: false,
      siteType:''
    };
  },
  computed: {
    ...mapState(["sovProductPageSave","sites"]),
    expandFunc() {
      if (!this.expandAll) {
        return [];
      } else {
        return this.cateRankListTable.map((v) => {
          return v.productId;
        });
      }
    },
    tableHeight() {
      let temp = 0;
      temp = this.isShowPriceFilter === false ? 345 : 400;
      return document.body.clientHeight - temp;
    },
  },
  mounted() {
    // this.getMonthFunc();
    this.init();
  },
  methods: {
    ...mapMutations({
      setProductPageSave: "SET_PRODUCTPAGESAVE",
    }),
    async getMonthFunc() {
      let res = await getSovAggMonth();
      console.log(res);
      if (res.code === 0) {
        // this.timeRange = res.data;
        storage.setItem("productTimeRange", res.data);
      }
    },
    init() {
      // this.getRankList();
      this.getCateTree();
      this.getProductMonth();
    },
    async getProductMonth() {
      let res = await getProductMonth();
      if (res.code === 0) {
        this.productMonth = res.data;
        this.tempTime = res.data;
        this.duration = [res.data, res.data];
      } else {
        this.$message.error("获取时间失败，请联系管理员!");
      }
    },
    // async getBrandMonth() {
    //   let res = await getBrandMonth();
    //   console.log(res);
    //   if (res.code === 0) {
    //     this.time = res.data;
    //     this.tempTime = res.data;
    //     this.duration = [res.data, res.data];
    //   } else {
    //     this.$message.error("获取时间失败，请联系管理员!");
    //   }
    // },
    submitPriceRange() {
      if (this.priceFloor === "") {
        this.priceFloor = 0;
      }
      if (this.priceCeiling === "") {
        this.priceCeiling = 999999999;
      }
      console.log(this.priceFloor, this.priceCeiling);
      if (this.priceFloor > this.priceCeiling) {
        this.priceFloor = this.priceCeiling;
      }
      this.getRankList();
    },
    cancelPriceRange() {
      this.priceCeiling = "";
      this.priceFloor = "";
      this.cateValue = null;
      this.siteType = ''
      this.getRankList();
    },
    changeDuration(val, time) {
      console.log(time, "time");
      if (time) {
        this.productMonth = time;
      } else {
        this.productMonth = this.tempTime;
      }
      this.duration = val;
      this.getRankList();
    },
    productSearch(val) {
      this.product = val;
      this.page = 1;
      this.currentPage = this.page;
      this.getRankList();
    },
    async getCateTree() {
      let res = await getCateTree();
      if (res.code === 0) {
        let temp = JSON.stringify(res.data)
          .replace(/tagId/g, "value")
          .replace(/tagName/g, "label");
        this.cateOptions = JSON.parse(temp);
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
      console.log(res);
    },
    async getRankList() {
      this.productListTableLoading = true;
      let res = await getCateRankList({
        // duration: this.duration[0],
        // duration: this.duration.length === 2 ? this.duration[1] : this.duration,
        startMonth: this.duration[0],
        endMonth: this.duration[1],
        product: this.product,
        cate: this.cateVal,
        page: this.page,
        size: this.size,
        sortColumn: this.sortColumn,
        sortDirection: this.sortDirection,
        priceFloor:
          this.priceFloor !== "" ? this.priceFloor * 100 : this.priceFloor,
        priceCeiling:
          this.priceCeiling !== ""
            ? this.priceCeiling * 100
            : this.priceCeiling,
        platform: this.siteType,
      });
      if (res.code === 0) {
        res.data.productInfos.forEach((v, i) => {
          for (let k in v) {
            if (v[k] === null) {
              v[k] = "--";
            }
          }
          v.index = ++i + this.size * (this.page - 1);
        });
        console.log(this.productList);
        if (this.productList.length !== 0) {
          res.data.productInfos = this.productList.concat(
            res.data.productInfos
          );
        }
        // 去除重复项
        let tempRes = new Map();
        res.data.productInfos = res.data.productInfos.filter(
          (a) => !tempRes.has(a.productId) && tempRes.set(a.productId, 1)
        );
        this.cateRankListTable = res.data.productInfos;
        this.total = res.data.total;
        this.productListTableLoading = false;
      } else {
        this.$message.error("获取数据失败，请联系管理员!");
      }
      console.log(res);
    },
    downReport() {
      if (this.productListId.length < 1) {
        this.$message.info({
          type: "info",
          message: "请至少选择1个产品",
        });
      } else if (this.productListId.length > 10) {
        this.$message.info({
          type: "info",
          message: "最多选择10个产品下载",
        });
      } else {
        console.log(2);
        this.downLoadRankingListReportFunc();
      }
    },
    async downLoadRankingListReportFunc() {
      downLoadSovProductRankingListReport(
        {
          startMonth: this.duration[0],
          endMonth: this.duration[1],
          id: this.productListId.join("|"),
        },
        `产品月投放数据.xlsx`
      )
        .then(() => {
          this.$message({
            type: "success",
            message: "文件正在下载",
          });
        })
        .catch(() => {
          this.$message.error("文件下载失败,请联系管理员!");
        });
    },
    productCompare() {
      if (this.productListId.length < 2) {
        this.$message.info({
          type: "info",
          message: "请至少选择两个产品去对比",
        });
      } else if (this.productListId.length > 5) {
        this.$message.info({
          type: "info",
          message: "最多选择5个产品做对比",
        });
      } else {
        const query = {
          products: this.btoa(this.productListId.toString()),
          productNameList: encodeURI(JSON.stringify(this.productNameList)),
          // duration:
          //   this.duration.length === 2 ? this.duration[1] : this.duration,
          duration: JSON.stringify(this.duration),
        };
        console.log(this.productNameList);
        this.$router.push({ name: "sovProductCompare", query });
      }
    },
    selectFuncHandle(item) {
      console.log(item);
      const temp = [];
      this.productList = item;
      item.forEach((v) => {
        temp.push({
          label: v.productName,
          value: v.productId,
        });
      });
      this.productNameList = temp;
      this.productListId = item.map((v) => v.productId);
      console.log(this.productNameList, this.productListId);
    },
    headerClickHandle(column) {
      if (
        column.property === "exposure" ||
        column.property === "sr" ||
        column.property === "sg" ||
        column.property === "cpi"
      ) {
        this.sortColumn = column.property;
        this.sortDirection = this.sortDirection === "desc" ? "asc" : "desc";
        this.getRankList();
        if (this.sortColumn === "exposure") {
          this.isSgSort = 0;
          this.isSrSort = 0;
          this.isCpiSort = 0;
          this.isExposurSort = this.sortDirection === "desc" ? 2 : 1;
        } else if (column.property === "sr") {
          this.isExposurSort = 0;
          this.isSgSort = 0;
          this.isCpiSort = 0;
          this.isSrSort = this.sortDirection === "desc" ? 2 : 1;
        } else if (column.property === "cpi") {
          this.isExposurSort = 0;
          this.isSgSort = 0;
          this.isSrSort = 0;
          this.isCpiSort = this.sortDirection === "desc" ? 2 : 1;
        } else {
          this.isExposurSort = 0;
          this.isSrSort = 0;
          this.isCpiSort = 0;
          this.isSgSort = this.sortDirection === "desc" ? 2 : 1;
        }
      }
    },
    expandAllFunc() {
      this.expandAll = !this.expandAll;
      this.isExpand = !this.isExpand;
    },
    disabledSelect(row) {
      if (this.productListId.includes(row.productId)) {
        return true;
      } else if (this.productListId.length > 9) {
        return false;
      } else {
        return true;
      }
    },
    getRowKey(row) {
      return row.productId;
    },
    toProduct(row) {
      // const query = {
      //   product: encodeURI(row.productName),
      //   productId: this.btoa(row.productId),
      //   duration: JSON.stringify(this.duration),
      // };
      this.$storage.setItem("productName", row.productName);
      // this.$router.push({ name: "sovProduct", query });
      this.setProductPageSave(this.page);
    },
    async nextPage(page) {
      this.page = page;
      this.currentPage = page;
      await this.getRankList();
      this.productList.forEach((row) => {
        this.$refs["multipleTable"].toggleRowSelection(row, true);
      });
    },
    sortChange(row) {
      console.log(row);
      this.sortColumn = row.prop;
      this.sortDirection = row.order === "descending" ? "desc" : "asc";
      this.getRankList();
    },
  },
  watch: {
    $route: {
      handler(val) {
        console.log(val);
        this.currentPage = this.sovProductPageSave;
        this.page = this.sovProductPageSave;
      },
      immediate: true,
    },
    cateValue(val) {
      console.log(val);
      this.productListId = [];
      this.productList = [];
      if (val === null) {
        this.cateVal = "";
      } else {
        this.cateVal = val[val.length - 1];
      }
      this.page = 1;
      this.currentPage = this.page;
      this.getRankList();
    },
  },
  components: {
    sovTitle,
  },
};
</script>

<style lang="scss" scoped>
.cateGoryWrap {
  width: 100%;
  height: 100%;

  .categoryTable {
    padding: 10px;
    margin-top: 10px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);

    .tab {
      position: relative;
      height: 30px;

      .timeSpan {
        position: absolute;
        right: 0px;
        top: 50%;
        transform: translateY(-50%);
      }
      .spanClass {
        display: inline-block;
        width: 61px;
        height: 28px;
        color: #fff;
        line-height: 28px;
        text-align: center;
        background: linear-gradient(180deg, #4d7ad7 0%, #5e8cec 100%);
        border-radius: 4px;
        cursor: pointer;
        font-size: 14px;
        margin-left: 6px;
      }

      .abClass {
        position: absolute;
        left: 13px;
        bottom: -32px;
        z-index: 900;
        font-size: 14px;
        color: #8c98a5;
      }
    }

    .tableMain {
      .expandClass {
        width: 92px;
        height: 28px;
        display: inline-block;
        line-height: 28px;
        text-align: left;
        padding-left: 8px;
        background: #f4f3f3;
        border-radius: 4px;
        cursor: pointer;

        i {
          margin-left: 10px;
        }
      }

      .isExpandClass {
        background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
        color: #fff;
      }

      .paddingClass {
        padding: 8px 0;

        span {
          display: inline-block;
          text-align: center;
        }
      }

      .borderClass {
        border-bottom: 1px solid #e7e7e7;
      }
    }

    .pagination {
      text-align: right;
      margin-top: 20px;
    }
  }
  .priceFilter {
    // display: none;
    background-color: #fff;
    padding: 10px 20px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    border: 1px solid #ebebeb;
    margin-top: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
}
</style>

<style lang="scss">
.cateGoryWrap {
  .spanInput .el-input__inner {
    height: 28px !important;
  }
  .selectClass {
    .el-scrollbar__view.el-select-dropdown__list {
      margin-top: 5px;
    }

    .el-select__caret.el-input__icon.el-icon-arrow-up {
      line-height: 28px;
    }

    font-size: 14px;

    .el-input__inner {
      width: 122px;
      height: 28px;
    }

    .el-input--suffix {
      height: 35px;
      line-height: 35px;
    }
  }

  .el-cascader {
    .el-input__inner {
      width: 202px;
      height: 28px;
    }
  }

  .searchClass {
    line-height: 28px;
    font-size: 14px;
    width: 160px;

    .el-input__inner {
      width: 200px;
      height: 28px;
    }

    .el-input--suffix {
      height: 35px;
      line-height: 35px;
    }
  }

  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    border-image: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    border-color: transparent;
    box-shadow: -1px 0 0 0 transparent;
  }

  .el-radio-button__inner {
    background-color: #f8f9fe;
  }

  .el-radio-button__inner:hover {
    color: #606266;
  }

  thead .el-table-column--selection .cell {
    display: none;
  }

  .el-table
    .el-table__header-wrapper
    .el-table__header
    thead
    > tr
    > th
    > .cell {
    color: #8c98a5;
    font-size: 14px;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #e09a29;
    border-color: #e09a29;
  }

  .el-checkbox__inner.is-focus .el-checkbox__inner {
    border-color: #e09a29;
  }

  .el-checkbox__inner:hover {
    border-color: #e09a29;
  }

  .el-table__expanded-cell {
    background-color: #f9fcff;
    padding: 0 !important;
    padding-left: 140px !important;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #e09a29;
    color: #fff !important;
  }

  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #e09a29;
  }

  .iStyle {
    position: absolute;
    color: #c0c4cc;
  }

  .iblue {
    color: #409eff;
  }

  .ibottom {
    top: 9px;
  }

  .itop {
    top: 2px;
  }
}

.categoryTable {
  .el-radio-button__orig-radio:checked + .el-radio-button__inner {
    background: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    border-image: linear-gradient(180deg, #daae3c 0%, #e09524 100%);
    border-color: transparent;
    box-shadow: -1px 0 0 0 transparent;
  }

  .el-radio-button__inner {
    background-color: #f8f9fe;
  }

  .el-radio-button__inner:hover {
    color: #606266;
  }

  thead .el-table-column--selection .cell {
    display: none;
  }

  .el-table
    .el-table__header-wrapper
    .el-table__header
    thead
    > tr
    > th
    > .cell {
    color: #8c98a5;
    font-size: 14px;
  }

  .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #e09a29;
    border-color: #e09a29;
  }

  .el-checkbox__inner.is-focus .el-checkbox__inner {
    border-color: #e09a29;
  }

  .el-checkbox__inner:hover {
    border-color: #e09a29;
  }

  .el-table__expanded-cell {
    background-color: #f9fcff;
    padding: 0 !important;
    padding-left: 140px !important;
  }

  .el-pagination.is-background .el-pager li:not(.disabled).active {
    background: #e09a29;
    color: #fff !important;
  }

  .el-pagination.is-background .el-pager li:not(.disabled):hover {
    color: #e09a29;
  }

  .iStyle {
    position: absolute;
    color: #c0c4cc;
  }

  .iblue {
    color: #409eff;
  }

  .ibottom {
    top: 9px;
  }

  .itop {
    top: 2px;
  }

  .selCate .el-input__inner {
    height: 28px !important;
    line-height: 28px !important;
  }

  .el-select__caret.el-input__icon.el-icon-arrow-up {
    line-height: 28px !important;
  }

  .el-table td.el-table__cell {
    padding: 8px;
  }
}
</style>
